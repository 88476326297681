
const BASE_URL = 'https://corporality.global';
//const BASE_URL = 'http://localhost:3000';

const API_HOME_BASE_URL = 'https://corporality.global/laravel-dashboard'; 
//const API_HOME_BASE_URL = 'http://localhost/corporality_localhost/laravel-dashboard'; 


const API_BASE_URL = API_HOME_BASE_URL + '/api';
const API_IMG_URL = API_HOME_BASE_URL + '/public/uploads/';
const TASKER_BASE_URL = 'https://tasker.corporality.global/api';
const CAMPAIGNER_BASE_URL = 'https://campaigner.corporality.global/api';
// "homepage": "https://corporality.global/mern",

export { BASE_URL, API_HOME_BASE_URL, API_BASE_URL, TASKER_BASE_URL, API_IMG_URL,CAMPAIGNER_BASE_URL }